<template>
    <!-- страница презентации -->
        <PageHeader :title="title" />
        <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
            <button @click="infoblockShow = !infoblockShow" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
        </div>

        <!-- инфо-блоки -->
        <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

        <!-- компонент таблицы -->
        <tablecustom 
            :objParams="objParams"
            :dataParams="dataParams"
            @search="searchB"    
            :pages="objPages"
            :showpag="true"
            :columns="columns" 
            :rows="rows"  
            :createdform="this.perms[111] || this.perms[2000]"
            :checks = "this.checkrows"
            :removeitem="this.perms[115] || this.perms[2000]"
            @removeAll="removeAll"
            @create="modalShow = true"  
            @open="open"
            @remove="remove" 
            @getdata="getdata"
            @changeFilter="changeFilter"
            @changelimit="changelimit" 
            @clearFilter="clearFilter" 
            @check="select"
        />

        <dialodel v-if="dialogshow" @close="dialogshow = false" :obj="item" @remove="remove" />
    <!-- создание заявки презент -->
    <addBox 
        v-if="modalShow"
        :perm="this.permToEdit" 
        :obj="this.form"
        @open = "openRequest"
        @closeLast = "closeLast"
        @close = "this.modalShow = false"/>

    <!-- карточка заявки презент -->
    <viewBox 
        @addCalendar = "addCalendar"
        v-for="form in viewForm"
        @edit="editPresentation"
        @close="closeView" 
        :shortapplic="cardApplic" 
        @getdata="getdata"
        @remove="remove" 
        @toConfirmed="toConfirmed"
        @toWork="toWork" 
        @toDone="toDone" 
        @clientCard = "openClient"
        @toCancel="toCancel"
        :ifPrevopen="ifPrevopen"
        :ifNextopen="ifNextopen" 
        @prevOpen="prevOpen" 
        @nextOpen="nextOpen"
    />

    <!-- карточка клиента -->
    <viewclientnew v-if="testshowModalclient == true" @close="testshowModalclient = false" :emp_phone="this.number" :emp_user="this.user_name" :obj="this.form" @call="call"/>
    <!-- карточка клиента -->

    <!-- модальне вікно створення івенту -->
    <addEvent
        v-if="showAddEvent == true"
        @close="showAddEvent = false"
        :crItem="crItem"
        @updateCard="updateCard"
    />
    <!-- модальне вікно створення івенту -->
</template>

<script>
import addEvent from '@/components/calendar/addEvent'
import viewclientnew from '@/views/clientnew/index.vue'
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import addBox from './add'
import viewBox from './view/index'
import infoblocks from '@/components/info/blocks'
import { Presentations } from '@/API.js';
import { storeS } from "@/store";
import dialodel from '@/components/globaltable/actions/dialogdelete.vue'
import { mutatePriority, mutateStatus } from '@/usabilityScripts/globalMutate.js'
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiServe = new Presentations();

export default{
    components: {
        addEvent,
        viewclientnew,
        dialodel,
        PageHeader,
        tablecustom,
        addBox,
        viewBox,
        infoblocks
    },
    data(){
        return{
            showAddEvent: false,
            removeitem: true,
            allSelected: '',
            crItem: '',
            viewForm: 0,
            showpag: false,
            title: this.$t('presentations'),
            search: "",
            dataParams: {
                status: true,
                page: "presentations"
            },
            permToEdit: false,
            selectstatus: 0,
            selectpriority: 0,
            removeall: false,
            modalShow: false,
            statuscreated: true,
            infoblockShow: false,
            ifNextopen: "",
            ifPrevopen: "",
            cardApplic: {},
            objPages: '',
            filterCount: 0,
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                status: '',
                priority: '',
                workerId: '',
                delayPr1:'', 
                delayPr2:"",
                pr1:'',
                pr2:''
            },
            columns: [
                // {
                //     name: this.$t('type'),
                //     text: "Application_type",
                //     align: "left",
                // },
                {
                    name: this.$t("phone_num"),
                    text: "userPhone",
                    align: "right",
                    status: true,
                },
                {
                    name: this.$t("Manager"),
                    text: "inJobWorkerId",
                    align: "right",
                    status: true,
                    mutate: (item) => item != null ? this.nameWorker(item) : this.checks['024'] ? 'Jesus' : '-' 
                },
                {
                    name: this.$t("Status"),
                    text: 'status',
                    align: "left",
                    status: true,
                    mutate: (item, obj) => this.mutateStatus(item, obj).name,
                    mutateClass: (item, obj) => this.mutateStatus(item, obj).color
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "right",
                    status: true,
                    mutate: (item) => this.mutatePriority(item).name,
                    mutateClass: (item) => this.mutatePriority(item).bg
                },
                // {
                //     name: this.$t("login"),
                //     text: "user_login",
                //     align: "right",
                // },
                {
                    name: this.$t("date_create"),
                    text: "createDatetime",
                    align: "right",
                    status: true,
                },
                {
                    name: this.$t("created"),
                    text: "createWorkerId",
                    align: "right",
                    status: true,
                    mutate: (item) => item ? this.nameWorker(item) : this.$t('fromSite')
                },
            ],
            rows:[],
            filterAll: [
                {
                    name: "Status",
                    category: "0",
                    items: []
                },
                {
                    name: "priority_type",
                    category: "0",
                    items: []
                }
            ],
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info"
                },
                {
                    title: this.$t('new'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success"
                },
                {
                    title: this.$t('statusInWork'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "warning"
                }
            ],
            checkrows: [],
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        select(e,a) {
            this.allSelected = a
            this.checkrows = e
        },
        addCalendar(e){
            this.crItem = e
            this.showAddEvent = true
        },
        closeView() {
            this.permToEdit = false;
            if(this.viewForm > 0) {
                this.viewForm--
            }
            storeS.checkModal.type = '';
            storeS.checkModal.id = '';
            this.getdata(this.objParams.page)
        },
        call(e,form) {
            this.$emit('call', e, form)
            localStorage.setItem('actualCall', e)
            this.eventBus.emit('actualCall', {
                status: true
            })

        },
        closeLast() {
            this.viewForm--
        },
        openRequest(e){
            this.cardApplic = e;
            this.modalShow = false;
            this.viewForm++

        },
        openClient(e) {
            this.form = e 
            this.viewForm--
            this.testshowModalclient = true;
        },
        changeFilter(a, b) {
            console.log(a,b)
            if(a == 'pr') {
                this.objParams.pr1 = b[0]
                this.objParams.pr2 = b[1]
            }
            this.objParams[a] = b
            this.getdata()
        },
        closeCreate() {
            this.permToEdit = false;
            this.modalShow = false
        },
        editPresentation(e) {
            this.permToEdit = true;
            this.form = e;
            this.modalShow = true
        },
        getdata(page){
            // page = page != undefined ? page : '1';
            // let params = "&page=" + page;
            this.objParams.page = page != undefined ? page : '1';
            apiServe.getList(this.objParams).then(result => {
                if(result.status === 'done') {
                    this.rows = result.data.items;
                    this.objPages = result.data;

                    this.information[0].value = result.data.total
                    this.information[1].value = result.data.countNew
                    this.information[2].value = result.data.countInJob

                    this.analiseOnCallNow()
                } else {
                    this.$toast.error(this.$t('error') + ` #110`);
                }
            })
        },
        analiseOnCallNow() {
            let arr = this.rows.filter(el => el.status === 'delayed')
            let min = new Date()
            let onCallNow = []

            arr.forEach(el => {
                console.log(el, (new Date(el.delayedDatetime) < min || new Date(el.delayedDatetime) === min))
                if((new Date(el.delayedDatetime) < min || new Date(el.delayedDatetime) === min) && el.status == 'delayed') {
                    onCallNow.push(el.presentationId)
                }
            })

            if(onCallNow.length > 0) {
                this.callNow(onCallNow)
            } else {
                let min = 0
                let minEl;
                arr.forEach((el, index) => {
                    if(index == 0) {
                        min = new Date(el.delayedDatetime).getTime()
                        minEl = el.presentationId
                    } else if(new Date(el.delayedDatetime).getTime() < min) {
                        min = new Date(el.delayedDatetime).getTime()
                        minEl = el.presentationId
                    }
                })
                if(minEl == null){
                    return
                }
                console.log(min - new Date().getTime())
                this.interval = setTimeout(() => {
                    this.callNow([minEl])
                }, min - new Date().getTime())
            }
        },
        callNow(arr) {
            apiServe.onAir({presentationIds: arr}).then(result => {
                if(result.status === 'done') {
                    this.getdata()
                }
            })
        },
        open: function(e){
            var id = e.presentationId ? e.presentationId : e;
            this.ifPrevopen = id == this.rows[0].presentationId ? false : true;
            this.ifNextopen = id == this.rows[this.rows.length-1].presentationId ? false : true;
            apiServe.getCard(id).then(result => {
                if(result.status == 'done'){
                    this.cardApplic = result.data;
                    this.viewForm++
                }
            })
        },
        prevOpen(e){
            var current_id = e;
            var prev_id = ""; 

            for(var value in this.rows){
                if(this.rows[value].presentationId == current_id){
                    prev_id = this.rows[Number(value)-Number('1')].presentationId;
                }
            }
            this.open(prev_id);
        },
        nextOpen(e){
            var current_id = e;
            var next_id = "";

            for(var value in this.rows){
                if(this.rows[value].presentationId == current_id){
                    next_id = this.rows[Number(value)+Number('1')].presentationId;
                }
            }
            this.open(next_id);
        },
        changelimit: function(limit){
            this.objParams.pagelimit = limit;
            this.getdata();
        },
        toConfirmed: function(e){
            apiServe.edit(e.presentationId, e).then(res => {
                if(res){
                    this.getdata();
                }
            })
            this.comment = "";
            this.$toast.success(this.$t('confirmationReceived'));
        },
        toWork: function(e){
        // ("all e,", e);
            apiServe.edit(e.presentationId, e).then(res => {
                if(res){
                    this.getdata();
                }
            })
            this.$toast.success(this.$t('tooktoWork') + " "+ this.user.name);
        },
        toDone: function(e){
        // ("all e,", e);
            apiServe.edit(e.presentationId, e).then(res => {
                if(res){
                    this.getdata();
                }
            })
            this.$toast.success(this.$t('performed') + " " + this.user.name);
        },
        toCancel: function(e){
        // ("all e,", e);
            apiServe.edit(e.presentationId, e).then(res => {
                if(res){
                    this.getdata();
                }
            })
            this.$toast.success(this.$t('cancelRe') + " " + this.user.name);
        },
        remove: function(e, form){
            apiServe.deleteCard(e.presentationId).then(res => {
                if(res.status === 'done'){
                    if(this.viewForm == 1) {
                        this.viewForm--;
                    }
                    this.getdata();
                    this.$toast.success(this.$t('Removed')); //удаленние презентации
                }
            })
        },
        removeAll: function(e){
            let obj = {presentations: []}
            for(var item in e) {
                obj.presentations.push(e[item].presentationId)
            }
            apiServe.removePresentations(obj).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'));
                    // this.modalShow = false;
                    this.getdata();
                }
            })
        },
        searchB(e){
            this.objParams.search = e
            this.getdata();
        },
        change(name, e){
            if(name == 'Status'){
                this.selectstatus = e;
            } else if(name == 'priority_type'){
                this.selectpriority = e;
            }
        },
        clearFilter(){
            this.objParams = {
                page: '1',
                pagelimit: '10',
                search: '',
                status: '',
                priority: '',
                workerId: '',
                delayPr1:'', 
                delayPr2:"",
                pr1:'',
                pr2:''
            },
            this.getdata()
        },
        nameWorker(item){
            return nameWorker(item)
        },
        mutateStatus(e, obj){
            return mutateStatus(e, obj);
        },
        mutatePriority(e){
            return mutatePriority(e);
        },
        mutaterequest(e){
            var r = "";
            switch(e){
                case "presentation":
                r = this.$t('presentation')
                break;
            }
            return {r};
        },
    },
    computed: {
        user() {
            return storeS.userbase
        },
        perms() {
            return storeS.perms
        },
        checks() {
            return storeS.checks
        },
        workers() {
            return storeS.workers
        }
    },
    mounted() {
        this.eventB.off('addNewPresentation')
        this.eventB.on('addNewPresentation', (data) => {
            this.getdata();
        })
    }
}
</script>